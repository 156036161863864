.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}


.App-link {
  color: #61dafb;
}

.navbar_wrap a{
  padding-left:30px !important;
  padding-top: 20px;
   
}



.App-link {
  color: #61dafb;
}
/*  */
.btn-wrapper button {
  background-color: green;
  border: 0px;
  padding: 10px;
  border-radius: 4px;
  color: #fff;
  box-shadow: #333 0px 1px 5px;
}
.img-wrapper img {
  width: 100px;
  margin-top: -10px;;
}
.cart-wrapper{
  height: 100px;
    width: 700px;
    border: 1px solid #ccc;
    margin-left: 10%;
    margin-bottom: 10%; 
}
.item {
  float: left;
  display: inline-block;
  width: 220px;
  padding: 20px;
  height: 70px;
}
.add-to-cart img{
  width: 60px;
    position: absolute;
    right: 1%;
    top: 2%;
}
.cart-count{
  
    position: absolute;
    right: 10px;
    top: 12px;
    z-index: 1;
    background-color: red;
    padding: 7px;
    width: 20px;
    height: 30px;
    border-radius: 50%;
    color: #fff;
}
/*  */
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}